body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


main{
  min-height: calc(100vh - 167px);
}

footer{
  clear: both;
  position: relative;
}





	li {font-family:Tahoma; font-size:10pt; padding:10px;}
	li.th {background-color:white}
	li.en {background-color:#dddddd}
	#btnaccept {
		font-family:Tahoma;
		font-size:10pt;
		font-weight: bolder;
		padding: 5px;
		color: white;
		background-color: #0064a4;
		border-style: outset;
	}
	#btndeny {
		font-family:Tahoma;
		font-size:10pt;
		font-weight: bolder;
		padding: 5px;
		color: white;
		background-color: #0064a4;
		border-style: groove;
	}



.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.ant-notification.ant-notification-topLeft{
  /* left: auto;
  top: auto; */
  width: 400px;
  /* height: 50%; */
  /* z-index: 1; */
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 40% !important;
  left: 0;
  bottom: 0;
  right: 0;
  /* border: solid black; */
}

.ant-notification-notice-icon {
  position: absolute;
  margin-left: -14px !important;
  font-size: 24px;
  line-height: 0px;

}

.ant-notification-notice {
  text-align: center;
  padding: 50px 24px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-left: 0px !important;
}
.anticon.ant-notification-notice-icon-error {
  font-size: 50px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-top: 40px;
  font-weight: 500;
  font-size: 15px;
  margin-left: 0px  !important;
}

@media only screen and (max-width: 600px) {
  .ant-notification.ant-notification-topLeft{
    width: 100%;
  }
}


.ant-notification-notice-btn {
  float: none !important;
  margin-top: 16px;
}

/*
.has-error .ant-form-explain, .has-error .ant-form-split {
  color: #f5222d;
}

.ant-form-item-control{
  padding-bottom: 10px;
} */

.ant-modal-confirm-body{
text-align: center;
}
.ant-modal-confirm-body .anticon.anticon-close-circle{
  float: none;
  font-size: 40px;
}

.ant-modal-confirm .ant-modal-confirm-btns{
  float: none !important;
  text-align: center;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  margin-top: 10px;
}

.ant-btn .anticon {
  padding: 3px;
}
.ant-btn-group.ant-dropdown-button{
  width: 260px;
}
.ant-btn.ant-dropdown-trigger{
  padding: 5px;
}

#form_login .ant-form-item-children input{
  width: 100% !important;
}

#form_login .ant-form-item-children  .ant-calendar-picker{
  width: 100% !important;
}

#form_login .login-form-button{
  width: 100% !important;
}


#login #form_login {
  width: 275px !important;
}

#login .ant-btn-group > .ant-btn:first-child:not(:last-child), .ant-btn-group > span:first-child:not(:last-child) > .ant-btn{
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

#login .ant-btn-group > .ant-btn:last-child:not(:first-child), .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}


.facebook.ant-btn-group.ant-dropdown-button .ant-btn.ant-dropdown-trigger.ant-btn-default{
  background: #4167B2;
  color: #fff;
}
.facebook.ant-btn-group.ant-dropdown-button .ant-btn.ant-btn-default{
  background: #4167B2;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .web-hide{
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .mobile-hide{
    display: none !important;
  }
}

.privacy-policy-ui li{
    font-size: 10pt;
    padding: 2px 15px;
}

.privacy-policy-ui  h5 {
    font-size: 16px;
    margin-top: 0.3em;
    margin-bottom: 0em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
}
