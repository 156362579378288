
@font-face {
  font-family: 'Kanit-Light';
  src: url('../../fonts/Kanit-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Kanit-Medium';
  src: url('../../fonts/Kanit-Medium.ttf') format('truetype');
}


html,body,a,div,h1,h2,h3,h4,h5,p,li,input {
  font-family: 'Kanit-Light';
}

.navbar {
  // height: 71px;
}

.app-main-container{
  max-width: 800px;
  margin-right: auto !important;
  margin-left: auto !important;
}

.card-view{
  padding: 10px;

}
.card-text{
  margin-top: 8px;
  font-size: 12px;
}


.card-view .card{
  padding: 0px 0px;
}
.card-view .card.banner{
  padding: 8px 8px;
  min-height: 350px;
}
.card-view .card.banner1{
  // padding: 0px 8px;
  // padding: 8px 8px;
  padding: 0px;
}


.main-form.card{
  // background-image: url(../../images/bg_login.png);
  // background-repeat: no-repeat;
  // background-size:100% auto;
  // padding-top: 75px;
  // left: 0;
  // top: 0;
  // box-shadow: none;
  // background-color: transparent;
  // margin-bottom: 30px;
  // min-height: 370px;
  // border-radius: 15px;
}
// style={{height: 'auto',paddingBottom: 40,minHeight: 500 }}
.bg_login{
  // background-image: url(../../images/QvFsqQsA.png);
  // background-repeat: no-repeat;
  // background-size:100% auto;
  padding-top: 85px;
  // left: 0;
  // top: 0;
  box-shadow: none;
  // background-color: transparent;
  // margin-bottom: 30px;
  min-height: 300px;
  border-radius: 15px;
  padding-bottom: 30px;
  // border: #5F92D0;
  max-width: 400px;
  border: 2px solid #5F92D0 !important;
  margin-right: auto;
  margin-left: auto;
  background: linear-gradient(0deg, #2561a9 30%, #5F92D0 90%);
  position: relative;
  // overflow: hidden;
  z-index: 10;
  &::after {
    content: '';
    position: absolute;
    background-image: url(../../images/bg-box.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 49% 100%;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    opacity: .09;
    z-index: -1;
  }
  &:before{
        border-radius: 30px;
    background-image: url(../../images/vector-icon.png);
    display: block;
    content: " ";
    background-repeat: no-repeat;
    background-size: auto 40%;
    position: absolute;
    background-color: #2867b5;
    top: 0px;
    left: 0%;
    width: 100%;
    height: 80px;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    background-position: center;
  }
}

.alphabox  .bg_login{
  &:before{
      background-image: url(../../images/alpha-box/logo2.png) ;
      // margin-top: 10px !important;
      // background-size: 50% auto !important;
  }
}

.bg_register{
  padding-left: 30px !important;
    padding-right: 30px !important;
  position: relative;
  // overflow: hidden;
  z-index: 10;
  // background-image: url(../../images/QvFsqQsA.png);
  // border: 2px solid #5F92D0 !important;
  // background-repeat: no-repeat;
  // background-size:100% auto;
  // background-size:100% 100%;
  padding-top: 85px;
  border: 2px solid #5f92d0!important;
  left: 0;
  top: 0;
  box-shadow: none;
  // background-color: transparent;
  // margin-bottom: 30px;
  min-height: 300px;
  max-width: 400px;
  border-radius: 15px;
  padding-bottom: 30px;
  margin-right: auto;
  margin-left: auto;
  background: linear-gradient(0deg, #2561a9 30%, #5F92D0 90%);
  position: relative;
  z-index: 10;
  &::after {
    // content: '';
    // position: absolute;
    // background-image: url(../../images/bg-box.jpg);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 49% 100%;
    // width: 100%;
    // height: 100%;
    // bottom: 0;
    // left: 0;
    // z-index: -1;
    // opacity: .09;
    content: '';
    position: absolute;
    background-image: url(../../images/bg-box.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 49% 100%;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    opacity: .09;
    z-index: -1;
  }
  &:before{
        border-radius: 30px;
    background-image: url(../../images/vector-icon.png);
    display: block;
    content: " ";
    background-repeat: no-repeat;
    background-size: auto 40%;
    position: absolute;
    background-color: #2867b5;
    top: 0px;
    left: 0%;
    width: 100%;
    height: 80px;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 100%;
    background-position: center;
    // border-radius: 15px;
    // background-image: url(../../images/vector-icon.png);
    // display: block;
    // content: " ";
    // background-repeat: no-repeat;
    // background-size: 65% auto;
    // position: absolute;
    // background-color: #2867b5;
    // top: 0px;
    // left: 0%;
    // width: 100%;
    // height: 90px;
    // height: 80px;
    // border-bottom-right-radius: 100%;
    // border-bottom-left-radius: 100%;
    // background-position: center;
  }
  // &:before{
  //   background-image: url(../../images/vector-icon.png);
  //   display: block;
  //   content: " ";
  //   background-repeat: no-repeat;
  //   background-size: 65% auto;
  //   position: absolute;
  //   top: 0px;
  //   left: 0%;
  //   width: 100%;
  //   height: 50px;
  //   background-position: center;
  // }
}

.alphabox  .bg_register{
  &:before{
      background-image: url(../../images/alpha-box/logo2.png)  ;
      // margin-top: 10px !important;
      // background-size: 50% auto !important;
  }
}
//

.nopadding{
  padding: 0px !important;
}



.bg-main{
  background:  linear-gradient(0deg, #1C59AA 30%, #0867DE 90%) ; //#
  color: #EBEDEC;
}
.color-org{
  color: #EA8E49
}
.navbar .header{
  font-size: 15px;

}

.padding-non{
  padding: 0px  !important;
}

.card-title {
  font-weight: 600;
  padding: 4px 8px 0px 28px;
  text-align: center;
}

#form_login{
  margin-left: auto;
  margin-right: auto;
}


label + .MuiInput-formControl {
margin-top: 0px !important;
}

.MuiTypography-body1{
  font-family: 'Kanit-Light' !important;
  margin-left: 6px !important;
  padding: 8px;
  font-size: 14px !important;
  font-weight: 400  !important;
  // padding-top: 15px;
  padding-right: 0px;
  color: #EBEDEC;
}
.MuiFormControlLabel-root{
  margin-right: 6px !important;
}

.MuiFormLabel-root{
  font-family: 'Kanit-Light' !important;
  color: #fff !important;
}

// .MuiFormLabel-root.Mui-focused{
//   color: #fff !important;
// }
.MuiFormLabel-root.Mui-focused{
  color: #eee !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgba(255, 255, 255, 0.87) !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #eee !important;
}

.MuiInputBase-input.MuiInput-input{
  color: #e9eaeb  !important;
}

.MuiInputBase-input{
  padding: 7px 0 3px   !important;
}

.text-color-main{
  color: #2761AC;
}
.text-color-main2{
  color: #F59C4F;
}

.fontweight600{
  font-weight: 600;
}

.card-title{
  min-height: 35px;
  margin-bottom: 0px  !important;

}

.app-main main{
  // background-image: url(../../images/BG.png);
  // // position: fixed;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
  // z-index: -1;
  // position: absolute;
  // width: 100%;
  // top: 0px;
  .album{
    // margin-top: 100px;
    min-height: 500px;
  }
}

body{
  background-image: url(../../images/BG.png);
  background-color: #EAEBEB  !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  // @media screen and (min-width: 800px){
  // background-size:100% auto;
  // background-repeat: no-repeat;
  // }
  // position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}


.sz-icons{
  .icon{
    // height: 20px;
    // width: 20px;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: 75% auto;
    // position: absolute;
    // margin-top: -24px;
    height: 25px;
    width: 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75% auto;
    position: relative;
    // margin-top: -25px;
    -webkit-margin-top-collapse: separate;
    top: -2px;
    left: -5px;
  }
  .username{
    background-image: url(../../icons/icon_username.png);
  }
  .password{
    background-image: url(../../icons/icon_password.png);
  }
  .email{
    background-image: url(../../icons/icon_email.png);
  }
  .date{
    background-image: url(../../icons/icon_date.png);
  }
  .citizen{
    background-image: url(../../icons/icon_citizen.png);
  }
  .passport{
    background-image: url(../../icons/icon_passport.png);
  }
  .gender{
    background-image: url(../../icons/icon_gender.png);
  }
  .occupation{
    background-image: url(../../icons/icon_occupation.png);
  }
  .nationality{
    background-image: url(../../icons/icon_nationality.png);
  }

  .mobile{
    background-image: url(../../icons/call.png);
  }
}



.sz-bt{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 40px;
  background-color: transparent;
  border-radius: 12px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-top: 8px;
  &.fb{
    .icon{
      background-color: #2B498F !important;
    }
  }
  &.mobile{
    .icon{
      background-color: #808080 !important;
    }
  }

  &.line{
    .icon{
      background-color: #04C201 !important;
    }
  }
  &.eregis{
    .icon{
      background-color: #B4462A !important;
    }
  }
  .icon{
    border-radius: 12px 0px 0px 12px;
    text-align: center;
    width: 50px;
    position: relative;
    flex: 0 0 20%;
    max-width: 20%;
    @media screen and (max-width: 400px){
      max-width: 30%;
    }
    padding: 0px 3px;
    cursor: pointer;
  }
  .body{
    flex: 0 0 80%;
    background-color: rgba(0, 0, 0, 0.1);
    max-width: 80%;
    @media screen and (max-width: 400px){
      // max-width: 70%;
    }
    display: flex;
    flex-wrap: wrap;
    border-radius: 0px 12px 12px 0px;
    &:hover{
      background-color: rgba(0, 0, 0, 0.2);
    }
    .text-full{
      text-align: left;
      flex: 0 0 100%;
      max-width: 100%;
      cursor: pointer;
      font-size: 11px;
      padding: 13px 0px 0px 10px;
      font-weight: 300;
      @media screen and (max-width: 400px){
        font-size: 9px;
      }
    }
    .text{
      text-align: left;
      flex: 0 0 85%;
      max-width: 85%;
      cursor: pointer;
      font-size: 11px;
      padding: 13px 0px 0px 10px;
      font-weight: 300;
      @media screen and (max-width: 400px){
        font-size: 9px;
      }
    }
    .end{
      cursor: pointer;
      flex: 0 0 10%;
      max-width: 10%;
    }
  }


}

.line-icon{
  background-repeat: no-repeat;
  background-size:100% 100%;
  background-image: url(../../images/line-icon.png);
  height: 42px;
  width: 42px;
  margin-left: auto;
  margin-right: auto;
}

.facebook-icon{
  background-repeat: no-repeat;
  background-size:100% 100%;
  background-image: url(../../images/facebook-icon.png);
  height: 42px;
  width: 42px;
  margin-left: auto;
  margin-right: auto;
}
.mobile-icon{
  background-repeat: no-repeat;
  background-size:100% 100%;
  background-image: url(../../images/47378-200-.png);
  height: 42px;
  width: 42px;
  margin-left: auto;
  margin-right: auto;
}

.email-icon{
  background-repeat: no-repeat;
  background-size:100% 100%;
  background-image: url(../../images/email_icon.png);
  height: 18px;
  width: 24px;
  margin: 12px 8px;
  margin-left: auto;
  margin-right: auto;

}

.arrow-icon{
  background-repeat: no-repeat;
  background-size:100% 100%;
  background-image: url(../../images/arrow.png);
  height: 9px;
  width: 16px;
  margin: 16px 8px;
  margin-left: auto;
  margin-right: auto;

}

.box-key{
  padding-left: 0px  !important;
  padding-right: 0px  !important;
}

.box-key.list {
  padding-left: 30px  !important;
  padding-right: 30px  !important;
  .card-view-row{
    padding-left: 10px  !important;
    padding-right: 10px  !important;
  }
}
.box-key.list2 {
  padding-left: 10px  !important;
  padding-right: 10px  !important;
}

@media screen and (min-width: 500px){
  .box-key.list {
    padding-left: 40px  !important;
    padding-right: 40px  !important;

  }
  .box-key.list2 {
    padding-left: 40px  !important;
    padding-right: 40px  !important;
  }
}


.card-view{
  // min-width: 350px;
  max-width: 400px !important;
  padding-left: 0px  !important;
  padding-right: 0px  !important;
}
.table thead th {
  vertical-align: top   !important;
  // border-bottom: 2px solid #dee2e6;
}

.detail-data{
  border: 2px solid rgba(255, 255, 255, 0.42) !important;
  border-radius: 6px;
  font-size: 12px;
  color: #fff;
  padding: 0px 8px;
  padding-bottom: 10px;
  @media screen and (max-width: 400px){
    font-size: 11px;
  }
  &.list{
    // font-size: 8px  !important;
    .table{
      font-size: 10px  !important;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      .td-100{
        // max-width: 50px;
        width: 65px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
      .td-70{
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .row{
    margin-top: 8px;
    .col-md-5{
      padding: 0px 8px;
    }
  }
}


.detail-data-time{
  border: 2px solid rgba(207, 175, 115, 0.82) !important;
  border-radius: 6px;
  font-size: 15px;
  color: #fff;
  padding: 4px;
}
.album{
  // min-width: 500px;
}


.pb-5, .py-5 {
  padding-bottom: 35px !important;
  padding-top: 35px !important;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 10px !important;
}

.card .card-body .deteil{
  background:  #EEEEEE;
  padding: 0px !important;
  font-size: 12px;
}
.card-body {
  padding: 0px 15px !important;
}

.change-password{
  margin-top: 10px;
  margin-bottom: 10px;
}

.bt-change-password{
  background: linear-gradient(45deg, #aaa 30%, #bbb 90%) !important;
  border-color: #aaa !important;
  border-radius: 5px;
  height: 30px !important;
  width: 200px;
  transition: opacity 0.3s;
  &:hover{
    background: linear-gradient(45deg, #aaa 80%, #bbb 90%) !important;
    opacity:0.9;
  }
}

.bt-singin{
  background: linear-gradient(45deg, #F89925 30%, #FDBA48 90%) !important;
  border-color: #f89932 !important;
  border-radius: 5px;
  height: 40px !important;
  transition: opacity 0.3s;
  &:hover{
    background: linear-gradient(45deg, #F89925 60%, #FDBA48 90%) !important;
    opacity:0.9;
  }
}

.bt-singin-other{
  background: rgba(127, 193, 224, 0.2) !important;
  border-color: #7FC1E0 !important;
  border-radius: 5px;
  height: 40px !important;
  color: #EBEDEC;
  text-align: center;
  font-size: 17px;
  transition: opacity 0.3s;
  &:hover{
    background: rgba(127, 193, 224, 0.6) !important;
    opacity:0.9;
  }
}

.bt-close{
  background: rgba(127, 193, 224, 0.6) !important;
  border-color: #7FC1E0 !important;
  border-radius: 5px;
  height: 40px !important;
  color: #EBEDEC;
  text-align: center;
  font-size: 17px;
  transition: opacity 0.3s;
  &:hover{
    background: rgba(127, 193, 224, 0.9) !important;
    opacity:0.9;
  }
}

.MuiFormControlLabel-root {
  display: inline  !important;
  position: relative;
  top: 7px;
  }

  .MuiTypography-body1 {
    position: relative;
    top: 3px;
    // left: -16px;
  }

  .gender{
    .MuiTypography-body1 {
      position: relative;
      top: 3px;
      left: -16px;
    }
  }

ul.agreement{
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

label {
  margin-bottom: 0px !important;
}



.contact-us{
  position: relative;
  background-color: #0F4D99;
  width: 100%;
  min-height: 100px;
  &::before {
    content: '';
    position: absolute;
    background: url(../../images/footer_bg.png);
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100px;
    background-size: cover;
    opacity: 0.2;
    @media screen and (min-width: 800px){
      background-position: 100% 15%;
    }
  }

  // background-size:100% 100%;

  .header{
    color:#fff;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
  }
  .sub-header{
    font-size: 12px;
    color:#fff;
    text-align: left;
    font-weight: 600;
  }
  .mini-icons{
    span.icons{
      padding-left:10px;
      @media screen and (max-width: 800px){
        padding-left: 3px;
        font-size: 9px;
      }
      color:#fff;
      font-weight: 600;
    font-size: 12px;
      img{
        margin-right: 10px;
        @media screen and (max-width: 800px){
          margin-right: 3px;
        }
      }
    }
  }
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  // top: 50%;
  width: 0% !important;
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text, .ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding:0px 8px 0px 0px !important;
}
.ant-divider-horizontal.ant-divider-with-text-left, .ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: -8px 0 !important;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}



.has-error .ant-form-explain, .has-error .ant-form-split {

}

.ant-form-explain{
  color: #fff !important;
  background: #FF6161;
  position: absolute;
  bottom: -20px  !important;
  font-size: 10px !important;
  line-height: 15px  !important;
  padding: 0px 12px  !important;
  min-height: 17px  !important;
  border-radius: 9px 0px;
  z-index: 980;
  opacity: 1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.3s;
  // cursor: pointer;
  &:hover{
    text-overflow: none   !important;
    white-space: normal;
  }
}

#form_login .ant-form-item-children input {
  width: 100% ;
  font-size: 14px;
}

.MuiInputLabel-formControl {
  top: -3px  !important;
}
.header-text{
  color: #fff;
  font-size: 16px;
  font-weight: 550;
  margin-top: 15px;
}

.header-sub-text{
  color: #fff;
  font-size: 12px;
}
.bt-sz-back{
  background-image: url(../../images/button_back.png);
  background-repeat: no-repeat;
  background-size:90px 45px;
  height: 45px;
  padding: 10px 40px;
  text-align: center;
  width: 80px;
  cursor: pointer;
  color: #878787;
  font-size: 14px;
  &.fix{
    position: absolute;
    right: -10px;
    top: 70px;
    z-index: 2;
    @media screen and (max-width: 600px){
      right: -5px;
    }
    // margin: 10px;
  }
}
// .login{
//   .fix{
//     right: 7px;
//   }
// }
// .forgot{
//   .fix{
//     right: -40px;
//   }
// }
// .regis{
//   .fix{
//     right: -35px;
//   }
// }

// .usage-info{
//   .fix{
//     right: -23px;
//   }
// }

// .info{
//   .fix{
//     right: -40px;
//   }
// }

.ant-modal-confirm .ant-modal-body{
  padding: 0 !important;
  // border-radius: 25px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
// .ant-modal-confirm .ant-modal-confirm-btns,
// .ant-modal-content{
//   // background-color: transparent !important;
// }
.ant-modal-confirm-body-wrapper{
  background: #fff;
  border-radius: 25px;
}
.ant-modal-confirm-error .ant-modal-content{
  background-color: transparent !important;
  .ant-modal-confirm-btns{
    margin:  0px !important;
  }
  .ant-btn-primary {
    padding: 15px;
    background: #fff;
    color: #111;
    height: 100%;
    width: 100%;
    border-radius: 0px 0px 25px 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.42) !important;
  }
  .ant-modal-confirm-body{
    border-radius: 25px 25px 0px 0px;
    height: auto;
    min-height: 180px;
    padding: 30px;
    background: #E85050;
    display: grid;
    .anticon-close-circle{
      color: #fff;
      font-size: 70px;
      margin: 0px 10px;
    }
    .ant-modal-confirm-title{
      color: #fff;
      font-size: 18px;
    }
    .ant-modal-confirm-btns{
      // margin-bottom: 20px;
    }
  }
}

.ant-modal-confirm-confirm .ant-modal-content{
  background-color: transparent !important;
  .ant-modal-confirm-btns{
    margin:  0px !important;
  }
  .ant-btn{
    padding: 15px;
    background: #eee;
    color: #111;
    height: 100%;
    width: 50%;
    margin: 0px;
    border-radius: 0px 0px 0px 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.42) !important;
  }
  .ant-btn-primary {
    padding: 15px;
    background: #fff;
    color: #111;
    height: 100%;
    // width: 100%;

    border-radius: 0px 0px 25px 0px !important;
    border: 1px solid rgba(255, 255, 255, 0.42) !important;
    background: #faad14;
  }
  .ant-modal-confirm-body{
    border-radius: 25px 25px 0px 0px;
    height: auto;
    min-height: 180px;
    padding: 30px;
    // background: #faad14;
        display: grid;
    .anticon-question-circle{
      color: #faad14;
      // color: #fff;
      font-size: 70px;
      margin: 0px 10px;
    }
    .anticon-exclamation-circle{
      color: #faad14;
      // color: #fff;
      font-size: 70px;
      margin: 0px 10px;
    }
    .ant-modal-confirm-title{
      color: #000;
      font-size: 18px;
    }
    .ant-modal-confirm-btns{
      // margin-bottom: 20px;
    }
  }
}


.ant-modal-confirm-warning .ant-modal-content{
  background-color: transparent !important;
  .ant-modal-confirm-btns{
    margin:  0px !important;
  }
  .ant-btn-primary {
    padding: 15px;
    background: #fff;
    color: #111;
    height: 100%;
    width: 100%;
    border-radius: 0px 0px 25px 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.42) !important;
  }
  .ant-modal-confirm-body{
    border-radius: 25px 25px 0px 0px;
    height: auto;
    min-height: 180px;
    padding: 30px;
    background: #faad14;
    display: grid;

    .anticon-exclamation-circle{
      color: #fff;
      font-size: 70px;
      margin: 0px 10px;
    }
    .ant-modal-confirm-title{
      color: #fff;
      font-size: 18px;
    }
    .ant-modal-confirm-btns{
      // margin-bottom: 20px;
    }
  }
}

.ant-modal-confirm-success .ant-modal-content{
  background-color: transparent !important;
  .ant-modal-confirm-btns{
    margin:  0px !important;
  }
  .ant-btn-primary {
      padding: 15px;
      background: #fff;
      color: #111;
      height: 100%;
      width: 100%;
      border-radius: 0px 0px 25px 25px !important;
      border: 1px solid rgba(255, 255, 255, 0.42) !important;
  }
  .ant-modal-confirm-body{
    display: grid;
    border-radius: 25px 25px 0px 0px !important;
    min-height: 180px;
    padding: 30px;
    background: #71CE5F;

    .anticon-check-circle{
      margin: 0px 10px;
      color: #fff;
      font-size: 70px;
    }
    .ant-modal-confirm-title{
      color: #fff;
      font-size: 18px;
    }
    .ant-modal-confirm-btns{
      // margin-bottom: 20px;
    }
  }
}

.ant-form-item-control {
  position: relative;
  line-height: 20px   !important;
  zoom: 1;
}
.navbar{
  padding: 0px   !important;
  .brand{
    width: 90px;
  }
  .header{
    padding-left: 4px;
  }
  @media screen and (max-width: 600px){
    .brand{
      width: 80px;
    }
    .header{
      display: none;
    }
  }


}

table {
  border-top: 0px solid rgba(255, 255, 255, 0.42) !important;
  border-bottom: 0px solid rgba(255, 255, 255, 0.42) !important;
}
.table{
  margin: 0px;
}
.table th, .table td {
    padding: 2px 3px  !important;
    color:#fff;
}

.table thead th {
  vertical-align: bottom;
  /* border-bottom: 2px solid #dee2e6; */
  border: 0px solid rgba(255, 255, 255, 0.42) !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.42) !important;
  margin: 0px;
}


.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(255, 255, 255, 0.42) !important;
}


.table tfoot td {
  border-top: 1px solid rgba(255, 255, 255, 0.42) !important;
}
.table tfoot td[colspan="2"] {
  border-top: 1px solid rgba(255, 255, 255, 0.21) !important;
}


  @media screen and (max-width: 768px){
    .navbar{
      position: fixed  !important;
      z-index: 999;
      width: 100%;
      top: 0px;
      left: 0;
    }


    .app-main{
      margin-top: 40px;
    }
  }

.building-info{
  .non-fixed{
    display: none;

  }
  .building-name{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* line-height: 23px; */
    max-height: 80px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

  &.fixed{
    position: fixed;
    top: 48px;
    left: 0;
    z-index: 99;
    width: 100%;
    transition: all 0.4s;
    text-align: center;
    .deteil{
      display: none;
    }
    .header{
      display: none;
    }
    .non-fixed{
      display:inline;
      width: 100%;
      .card-title{
        margin-top: 4px;
      }
      .text-building{
        position: absolute;
        left: 0;
      }
      .image{
        // text-align: left;
        // margin-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        // padding-left: 50px;
        margin-left: auto;
        margin-right: auto;
        // position: absolute;
        // top:10px;
        // left: 40px;
        // @media (min-width: 576px){
        //   top:10px;
        //   left: 90px;
        // }
        div{
          width:50px;
        }




        // padding: 16px 0px;
        img{
          height:19px;
        }
      }
      .ip{
        text-align: right;
        font-size: 8px;
        margin-top: -14px;
        padding: 0px 20px 0px 10px;
      }
    }
  }


  background: #fff;

}


.abs-info{
  background: transparent;
  transition: transform 0.3s;
  overflow: hidden;
  &.fixed{
    transform: scale(0.5);
    position: fixed;
    width: 300px;
    bottom: 10px;
    right: 10px;
    z-index: 998;
    width: 100%;
    height: 100%;
    max-height: 250px;
    max-width: 300px;
    text-align: center;
    transform-origin: right bottom;
    div{
      width: 100%  !important;
    }
    a{
      background: #fff;
      position: absolute;
      right: 15px;
      // max-width: 200px;
      padding: 5px !important;
      border-radius: 5px;
    }
    iframe,a{
      background: #fff;
      position: absolute;
      bottom: 0px;
      right: 0px;
      // right: 15px;
      // max-width: 200px;
      padding: 0px !important;
      border-radius: 5px;
      // transform: scale(0.5);

    }
    video{
      position: fixed;
      width: 200px !important;
    }
  }
}

.ads-video{
  iframe{
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.auto-center{
  margin-left: auto;
  margin-right: auto;
}
.card-view-row{
  padding: 0px 0px;
}

button:focus {
 outline: none !important;
}

.main-form{
  margin-bottom: 0px;
  @media screen and (min-width: 800px){
    // margin-bottom: 300px;
  }
}


.stack-grid-mobile{
  >div{
    position: static !important;
    height: auto !important;
    >span{
      position: static !important;
      display: block !important;
    }
  }
  .show-md{
    display: none;
  }
  .hide-md{
    display: block;
  }
}

.stack-grid{
  .show-md{
    display: block;
  }
  .hide-md{
    display: none;
  }
}


.ant-btn-link{
  color: #F59C4F  !important;
  &:hover{
    color: #E6AE4F !important;
    text-decoration: underline;
  }
}


.ads-mask{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  img{
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    max-height: 100%  !important;
    margin: auto  !important;
    width:auto !important;
    height:auto !important;
    overflow: auto;
    max-height: 80% !important;
    max-width: 350px  !important;
    @media screen and (min-width: 700px){
      // margin-top: 48px !important;
    }
  }
}

.ads-mask-2{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1072;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: opacity .15s linear;




  .modal.show .modal-dialog {
      -webkit-transform: none;
      transform: none;
  }

  .modal.fade .modal-dialog {
      transition: -webkit-transform .3s ease-out;
      transition: transform .3s ease-out;
      transition: transform .3s ease-out,-webkit-transform .3s ease-out;
      -webkit-transform: translate(0,-50px);
      transform: translate(0,-50px);
  }
    .modal-dialog-centered {
       min-height: calc(100% - 3.5rem);
    }
    .modal-dialog {
        max-width: 370px;
        margin: 1.75rem auto;
    }

  .modal-dialog-centered {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      min-height: calc(100% - 1rem);
      margin-right: auto !important;
      margin-left: auto !important;

  }
  .modal-dialog {
      position: relative;
      width: auto;
      margin: .5rem;
      pointer-events: none;
  }

  .modal-body {
      text-align: center;
    }

  .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: transparent;
    outline: 0;
}

.modal-footer2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
  }
}
.box-key{
  padding-top: 4px;
  padding-bottom: 4px;
}

.ant-form-item label {
  position: relative;
  color: #BAD8FE!important;
}

.MuiTypography-body1,.MuiTypography-body1_{
  color: #BAD8FE!important;
  font-size: 12px !important;
}


.menu-profile{
  color: #2761AC !important;
  font-weight: 500;
  i{
    padding-right: 3px;
  }
}
.MuiInputLabel-shrink{
  // margin-top: 10px;
  position: relative;
  top: 8px !important;
}


input:-webkit-autofill {
  -webkit-transition-delay: 9999999s;
  transition-delay: 9999999s;
}

input:-webkit-autofill {
  background-color: transparent !important;
}


 .ant-form-item .rq label{
    &:after{
      content: " *";
      color: red;
      // text-align: right;
      // float:right;
    }
  }


  .rq-lable{
    &:after{
      content: "*";
      color: red;
    }
  }

  button:disabled,
  button[disabled]{
    color: rgba(0, 0, 0, 0.25)  !important;
    // background: #f5f5f5  !important;
    background: #bbbbbb  !important;
    border-color: #d9d9d9  !important;
    &:hover{
      background: #bbbbbb  !important;
      border-color: #d9d9d9  !important;
      color: rgba(0, 0, 0, 0.3)  !important;
    }
  }


  .ant-form label {
    font-size: 11px !important;
}

.MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: -8px  !important;
}


.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #0B60AB;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 20px !important;
  height: 20px !important;
  margin-right: -1px !important;
  // font-family: Arial;
  line-height: 12px  !important;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  /* border-radius: 4px; */
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  display: inline-block;
  min-width: 20px !important;
  height: 20px !important;
  color: rgba(0, 0, 0, 0.65);
  // font-family: Arial;
  line-height: 12px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 4px;
  cursor: pointer !important;
  transition: all 0.3s;
}


.ant-pagination li {
  /* font-family: Tahoma; */
  /* font-size: 10pt; */
  cursor: pointer !important;
  padding: 0px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  font-size: 12px !important;
  color: #fff;
}

li.ant-pagination-item{
  padding: 5px 0px;
  line-height: 14px;
  font-size: 14px;
  color: #fff;
  font-family: 'Kanit-Light';
}

.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
  margin-top: -4px !important;

}

.ant-pagination-item-active {
  font-weight: 500;
  background: #2867B5;
  border-color: #2867B5;
  color: #111 !important;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  color: #fff  !important;
}

.ant-pagination-item-ellipsis{
  color: #fff  !important;
}
.ant-pagination-disabled{
color: rgba(225, 225, 225, 0.65) !important;
}


.ant-pagination-item-link-icon.anticon-double-right,.anticon-double-left{
  svg{
    // color: red;
    color: #fff  !important;
    margin-top: -7px;
  }
}


.MuiPickersToolbar-toolbar{
  background: linear-gradient(0deg, #1C59AA 30%, #0867DE 90%);
  color: #EBEDEC;
}

.MuiPickersDay-daySelected {
  // color: #fff;
  // font-weight: 500;
  background-color: #0867DE    !important;
}

.MuiButton-textPrimary {
  color: #0867DE    !important;
}
.MuiTypography-colorPrimary {
  color: #0867DE    !important;
}



.tooltip-sc {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip-sc .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #fff;
  color: #2761AC;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1000;
  top: 150%;
  left: -150px;
  margin-left: -60px;
  padding: 8px;
  font-size: 10px;
  line-height: 12px;
  transition: visibility 0.2s;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  h5{
    font-size: 12px;
    color: #2761AC;
  }
}

.MuiFormHelperText-root {
  color: #fff !important;
  margin: 0;
  font-size: 10px !important;
}

.tooltip-sc .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 220px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent  transparent  #fff transparent;
}
// .tooltip-sc:hover .tooltiptext {
//   visibility: visible;
// }


.tooltip-sc.active .tooltiptext {
  visibility: visible;
}

.MuiSelect-icon{
  color: #fff !important;
}


.MuiInputBase-input.MuiInput-input{
  font-size: 14px;
  font-family: 'Kanit-Light';
}


.MuiFormControl-marginNormal {
   margin-top: 0px  !important;
   margin-bottom: 0px  !important;
}


.MuiMenuItem-root {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 25px  !important;
  font-family: 'Kanit-Light' !important;
  font-weight: 400;
  line-height: 10px;
  font-size: 12px !important;
  padding-top: 4px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  /* padding-bottom: 4px; */
}

.MuiPaper-root{
  // margin-top: 40px;
}


#login-{
  padding-left: 30px;
  padding-right: 30px;
  // padding-left: 30px !important;
  // padding-right: 30px !important;
  @media screen and (max-width: 400px){
    padding-left: 8px;
    padding-right: 8px;
      .form_logout,.detail-data{
        margin-right: 10px;
        margin-left: 10px;
      }
    }
}

.nowrap-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 0px;
}

.gc-reset{
  display: inherit;
  margin-top: 10px;
  background: #fff;
  transform:scale(0.90) !important;
  -webkit-transform:scale(0.90) !important;
  iframe,div{
    width: 100% !important;
  }
  iframe{
    // border: 1px solid #d3d3d3;
    border-radius: 3px;
  }

  // transform-origin:0 0 !important;
  // -webkit-transform-origin:0 0 !important;
  // transform:scale(0.90) !important;
  // -webkit-transform:scale(0.90) !important;
  // @media screen and (max-width: 380px){
  //   transform:scale(0.95) !important;
  //   -webkit-transform:scale(0.95) !important;
  // }
  //   @media screen and (max-width: 370px){
  //     transform:scale(0.90) !important;
  //     -webkit-transform:scale(0.90) !important;
  //   }
  //   @media screen and (max-width: 350px){
  //     transform:scale(0.80) !important;
  //     -webkit-transform:scale(0.80) !important;
  //   }
  //   @media screen and (max-width: 330px){
  //     transform:scale(0.75) !important;
  //     -webkit-transform:scale(0.75) !important;

  //   }
  //   @media screen and (max-width: 310px){
  //     transform:scale(0.70) !important;
  //     -webkit-transform:scale(0.70) !important;
  //   }
  //   @media screen and (max-width: 290px){
  //     transform:scale(0.65) !important;
  //     -webkit-transform:scale(0.65) !important;
  //   }
  }


  .auto-height{
    height: auto !important;
    min-height: 40px !important;
  }




input[type="password"]
{
  text-security:disc !important;
  -webkit-text-security:disc;
}


.detail-nowrap{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center !important;
  margin-right: 0px;
  margin-left: 0px;
}


*:focus {
  outline: none;
}

._alert-warning{
  color: #ffd300;//#ffd300;
  font-size: 13px;
  text-align: center;
}


.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: -55px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1000;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown.menu.hover .dropdown-content {
  display: block;
  z-index: 1000;
}

.dropdown.menu.hover .dropbtn {
  background-color: #3e8e41;
}


.dropdown-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}


.card-,.card{
//  max-width: 350px;
}


input:-internal-autofill-selected {
  background-color: transparent !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

.MuiGrid-root.MuiGrid-item{
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.regis-fm{
  .MuiGrid-root.MuiGrid-item{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .MuiTypography-root {
    /* margin: 0; */
    padding-right:16px;
    padding-left: 0;
  }
  .MuiButtonBase-root {
    padding: 8px 0px 8px 8px;
  }
}


#form_login{
  margin-top: 18px;
}
a{
  font-size: 12px ;
}


.ads-AdsBanner{
  img{
    max-width: 400px !important;
    width: 100%;
  }
}
.act-a{
  height: 100px;
}
.act-b{
  height: 75px;
}

.act-data{
  font-family: 'Kanit-Light';
  position: absolute;
  width: 106%;
  left:-3%;
  background: #fff;
  top: 145px;
  padding: 8px;
  font-size: 9px;
  @media screen and (max-width: 365px){
      // font-size: 10px;
  }
  @media screen and (max-width: 340px){
      font-size: 8px;
  }
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .item-1{
      color: #EE873f;
      margin-left: 12px;
      margin-right: 12px;

  }
  .item-2{
    margin-left: 12px;
    margin-right: 12px;


  }
}
.required{
  color: #EE2427;
}

.regis-fm-data{
  .MuiGrid-align-items-xs-flex-end {
    // align-items: flex-start;
  }
  .sz-icons .icon{
    // margin-top: 15px;
  }
  .regis-fm .MuiTypography-root{
     padding-right: 0px !important;
  }


}
.regis-fm .MuiTypography-root{
       padding-right: 4px !important;
}

.sk-top{
  transition: height 0.6s ease-in;
  @media (max-width: 768px){
    // position: absolute;
    // top: 50px;
    // width: 100%;
  }
}



.sk-fixed-height{
  // min-height: 60px;
  // position: absolute;
  // width: 100%;
}
.sk-fixed-height.sk-top {
    // bottom: 0;
    // top: auto;
}

.sk-fixed-height {
    // position: absolute;
    // width: 100%;
}

@media (min-width: 1024px){
  .sitekit-smart-bar-wrap {
      padding: 12px 52px 0;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
  }
}

@media (min-width: 768px){
  .sitekit-smart-bar-wrap {
      padding: 20px 56px 12px;
  }
}

.sitekit-smart-bar-wrap {
      max-height: 50px;
    padding: 16px 0 12px;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -moz-align-items: center;
    align-items: center;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    background-color: rgba(255, 234, 205,0.7);
    @media (max-width: 768px){
      position: absolute;
      top: 50px;
      width: 100%;
      // margin-top: 50px;
    }
  .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: .75rem 1.25rem;
      color: inherit;
      @media (max-width: 768px){
          // display: none;
      }
  }
}

.sitekit-background-pattern1:before {
    content: '';
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: -1;
    background: url(../../images/footer_bg.png);
    background-size: 500px 100px,cover;
    background-color: #fff3cd;
    @media (max-width: 768px){
          // top: 50px;
      // margin-top: 50px;
    }
}


@media (min-width: 1024px){
  .sitekit-element-message {
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: 24px;
  }
}

@media (min-width: 768px){
  .sitekit-element-message {
      padding: 0 56px;
  }
}

.sitekit-element-message {
    opacity: 0;
    transform: translateY(0%);
    display: block;
    margin: 0;
    color: #856404;
    max-width: 100%;
    text-align: center;
    padding: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    padding: 0 32px;
    text-overflow: ellipsis;
    .animated{
      opacity: 1;
    }
}

.sitekit--smart-bar-padding{
  // position: absolute;
  //   right: 100px;
  //   color: #fff;
}

.ant-divider-horizontal {
    margin: 15px 0 !important;
}

.abs-info{
  a{
    display: block !important;
  }
  img{
   max-width: 350px  !important;
  }
}

.facebooklike.disabled::before{
  content: ' ';
  // color: #fff;
  // background: red;
  z-index: 1000;
  height: 60px;
  width: 300px;
  position: absolute;
}

.donedeal .navbar-brand .header{
  display: none;
}

@media (max-width: 480px){
  .donedeal .navbar-brand{
    display: none !important;
  }
}

.logo-donedeal {
    width: 100%;
    height: 55px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-done {
    width: 100%;
    // height: 105px;
    position: relative;
    background-color: #2761AC;
}
.typeheader-4 {
    color: #fff;
}

.done-main{
  // background-color:#004986;
  // border-top: 1px solid #2761AC;
  // border-bottom: 2px solid #2761AC;
  box-shadow: 0px 8px 5px -5px rgba(168, 168, 168, 0.8);
  background-color:#FFF;
  position: relative;
  z-index: 2;
  .detail-data-ui{
    font-size: 12px;
    font-weight: 700;
    padding: 8px 8px 10px;
    padding-bottom: 10px;
    border-radius: 0px;
    // color: #fff;
    // border: 2px solid #fff;
    color: #004986;
    border: 2px solid #004986;


  }
}



// html {
//   margin: 0;
//   width: 100%;
//   height: 100%;
// }
// body {
//   margin: 0;
//   padding: 0;
//   position: relative;
//   width: 100%;
//   height: 100vh;
//   overflow: hidden;
//   font-family: 'Kanit', sans-serif;
// }
.covid-19 svg {
  position: absolute;
  margin: 0 auto;
  text-align: center;
}
.covid-19-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.covid-19 .container {
  max-width: 530px;
  margin: 0 auto;
}
.covid-19.footer {
  width: 100%;
  padding: 20px;
  position: fixed;
  background-color: #353538;
  bottom: 0;
}
@media (max-width: 767px) and (min-width: 320px) {
  .covid-19.footer {padding: 0px;}
}
.covid-19__content1 {
  position: relative;
  border-radius: 8px;
  padding: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-area: a;
  background: #FBBA3B;
  padding: 1.8rem 1.5rem;
}
.covid-19__content2 {
  position: relative;
  border-radius: 8px;
  padding: 0.8rem 0 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-area: b;
  background: #ea5771;
}
.covid-19__content3 {
  position: relative;
  border-radius: 8px;
  padding: 0.8rem 0 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-area: c;
  background: #039245;
}
.covid-19__content4 {
  position: relative;
  border-radius: 8px;
  padding: 0.8rem 0 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-area: d;
  background: #FBBA3B;
}
.covid-19__content5 {
  position: relative;
  border-radius: 8px;
  padding: 0.8rem 0 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-area: e;
  background: #d22d36;
}
.covid-title {
  position: relative;
  border-radius: 8px;
  padding: 0.8rem 0 0.5rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  grid-area: t;
  font-size: 40px;
}
.grid-content {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 2fr 1.2fr 1.2fr;
  grid-template-areas:
      't b c'
      'a d e';
}
.covid-19 span {
  font-size: 18px;
  color:#FFF;
}
  @media screen and (max-width: 399px) {
    .ab-img.brand{
          display: none;
        }
    }


  @media screen and (min-width: 400px) {
    .ab-img.brand-min{
      display: none;
    }
  }
