@charset "utf-8";
/* CSS Document */
@font-face {
    font-family: 'edpensookregular';
    src: url('../fonts/EDPenSook-webfont.eot');
    src: url('../fonts/EDPenSook-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/EDPenSook-webfont.woff2') format('woff2'),
         url('../fonts/EDPenSook-webfont.woff') format('woff'),
         url('../fonts/EDPenSook-webfont.ttf') format('truetype'),
         url('../fonts/EDPenSook-webfont.svg#edpensookregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'edpensookbold';
    src: url('../fonts/EDPenSook-Bold-webfont.eot');
    src: url('../fonts/EDPenSook-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/EDPenSook-Bold-webfont.woff2') format('woff2'),
         url('../fonts/EDPenSook-Bold-webfont.woff') format('woff'),
         url('../fonts/EDPenSook-Bold-webfont.ttf') format('truetype'),
         url('../fonts/EDPenSook-Bold-webfont.svg#edpensookbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

