$bg-primary: #454545;
$color: #FFF;
.theme-darkmode.change-theme-preview{

body {
  background-image: none;
  background-color: lighten($bg-primary, 5%) !important;
}

.bg-main {
  background: linear-gradient(0deg, darken($bg-primary, 10%) 30%, $bg-primary 90%) !important;
  color: $color;
}

.contact-us {
  background: linear-gradient(0deg, $bg-primary 30%, darken($bg-primary, 10%) 90%) !important;
}

.bg_login {
  border: 2px solid $bg-primary !important;
  background: linear-gradient(0deg, darken($bg-primary, 10%) 30%, $bg-primary 90%);
  z-index: 10;
  &:before{
    background-color: darken($bg-primary, 10%);
  }
}

.bg_register{
  border: 2px solid $bg-primary !important;
  background: linear-gradient(0deg, darken($bg-primary, 10%) 30%, $bg-primary 90%);
  z-index: 10;
  &:before{
    background-color: darken($bg-primary, 10%);
  }
}

.MuiInputBase-input.MuiInput-input {
  color: $color !important;
}
.MuiFormLabel-root.Mui-focused {
  color: $color !important;
}
.MuiTypography-body1, .MuiTypography-body1_ {
  color: $color !important;
}

.MuiFormControlLabel-root a {
  color: red !important;
}

.ant-form-item label {
  color: $color !important;
}

.card-title ,.text-color-main2{
    color: #a0a0a0;
}


// .bt-singin {
//   background: linear-gradient(45deg, darken($bg-primary, 30%) 30%, darken($bg-primary, 20%) 90%) !important;
//   border-color: darken($bg-primary, 30%) !important;
//   &:hover {
//     background: linear-gradient(45deg, darken($bg-primary, 40%) 30%, darken($bg-primary, 30%) 90%) !important;
//     border-color: darken($bg-primary, 30%) !important;
//   }
// }

// .bt-singin-other {
//   background: rgba($bg-primary, 0.5) !important;
//   border-color: $bg-primary !important;
//   color: $color;
//   &:hover {
//     background: rgba(darken($bg-primary, 20%), 0.5) !important;
//     border-color: $bg-primary !important;
//   }
// }

// .StopIcon-root-212 {
//   color: darken($bg-primary, 30%) !important;
// }

.ant-btn-link {
  color: darken($color, 40%) !important;
  &:hover {
    color: darken($color, 30%) !important;
  }
}

.building-info {
  background: darken($bg-primary, 10%);
}
.card .card-body .deteil {
  background: $bg-primary;
}
.text-color-main {
  color: $color;
}

.card {
  box-shadow: 0px 8px 5px -5px rgba(darken($bg-primary, 20%), 0.8);
}
}
